import clsx from 'clsx';
import React, { RefObject, useEffect, useState } from 'react';

export function SearchBarInput({
  inputRef,
  isMobile,
  placeholder,
  multiplePlaceholders,
  defaultPlaceHolder,
  openMiniSearch,
  inputValue,
  inputOnClick,
  inputOnChange,
  style,
}: {
  inputRef: RefObject<HTMLInputElement>;
  isMobile: boolean;
  placeholder: string | undefined;
  multiplePlaceholders: string[];
  defaultPlaceHolder: string;
  openMiniSearch: boolean;
  inputValue: string;
  inputOnClick: () => Promise<void>;
  inputOnChange: React.ChangeEventHandler<HTMLInputElement>;
  style: React.CSSProperties | undefined;
}) {
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % multiplePlaceholders?.length
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [multiplePlaceholders?.length]);

  return (
    <input
      ref={inputRef}
      type='search'
      name='search'
      placeholder={
        (isMobile && multiplePlaceholders
          ? multiplePlaceholders?.[placeholderIndex]
          : placeholder) || defaultPlaceHolder
      }
      className={clsx(
        'headerSearchPlaceholder w-full rounded-md py-[12px] text-[16px] transition-all lg:px-12 ltr:pl-11 rtl:pr-11',
        'border border-light-gray bg-white focus:!border-transparent focus:!outline-transparent focus:!ring-transparent focus:!ring-offset-0 lg:border-2 lg:border-transparent',
        openMiniSearch
          ? '!text-black lg:bg-white lg:focus:bg-white'
          : 'lg:bg-blue-light lg:focus:bg-blue-light',
        'lg:placeholder:text-white-500',
        'placeholder:text-light-gray'
      )}
      style={style}
      aria-label='Search'
      autoComplete='off'
      autoCorrect='off'
      autoCapitalize='off'
      spellCheck={false}
      maxLength={512}
      value={inputValue}
      onChange={inputOnChange}
      onClick={() => {
        isMobile ? inputOnClick() : null;
      }}
      onKeyUp={() => {
        !isMobile ? inputOnClick() : null;
      }}
    />
  );
}
