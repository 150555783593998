import type { Hit as AlgoliaHit } from 'instantsearch.js';
import type { AlgoliaProduct } from 'types/product';

// Note: This is a temporary workaround for getting products.
// Normally, this should be done through our REST API.
export function hitToProduct(hit: AlgoliaHit): AlgoliaProduct {
  return {
    id: hit.objectID,
    name: hit.name,
    url: hit.url,
    visibility_search: hit.visibility_search,
    visibility_catalog: hit.visibility_catalog,
    type_id: hit.type_id,
    ordered_qty: hit.ordered_qty,
    rating_summary: hit.rating_summary,
    categories: hit.categories,
    categories_without_path: hit.categories_without_path,
    categoryIds: hit.categoryIds,
    thumbnail_url: hit.thumbnail_url,
    image_url: hit.image_url,
    in_stock: hit.in_stock,
    search_language: hit.search_language,
    manufacturer: hit.manufacturer,
    price: hit.price,
    sku: hit.sku,
    rx_items: hit.rx_items,
    sold_out: hit.sold_out,
    limited_stock: hit.limited_stock,
    product_type_string: hit.product_type_string,
    created_at: hit.created_at,
    global_filter: hit.global_filter,
    product_form: hit.product_form,
    fulfilment_source: hit.fulfilment_source,
    redbox_pl_custom_image_url: hit.redbox_pl_custom_image_url,
    redbox_pl_custom: hit.redbox_pl_custom,
    promo_rank: hit.promo_rank,
    gtin: hit.gtin,
    imf_category: hit.imf_category,
    imf_department: hit.imf_department,
    item_has_offer: hit.item_has_offer,
    redbox_pl_start_date: hit.redbox_pl_start_date,
    redbox_pl_end_date: hit.redbox_pl_end_date,
    imf_division: hit.imf_division,
    imf_sub_category: hit.imf_sub_category,
    imf_class: hit.imf_class,
    hidden_item: hit.hidden_item,
    store_ar: hit.store_ar,
    store_en: hit.store_en,
    store_uae_ar: hit.store_uae_ar,
    store_uae_en: hit.store_uae_en,
    max_qty: hit.max_qty,
    algoliaLastUpdateAtCET: hit.algoliaLastUpdateAtCET,
    __position: hit.__position,
  };
}
