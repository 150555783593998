import BaseSwiper from 'components/swiper/base-swiper';
import './recomendation.css';
import { HomePageCategoryListItemType } from 'types/cms/product-carousel';
import { Link } from 'components/link';
import clsx from 'clsx';

export default function RecommedationFilters({
  categories,
  itemBackgroundColor,
  itemLabelText,
  componentIndex,
}: {
  categories: HomePageCategoryListItemType[];
  itemBackgroundColor: string;
  itemLabelText?: string;
  componentIndex?: number;
}) {
  return (
    <div className='relative my-4'>
      <BaseSwiper
        className='recommedation-slider'
        swiperProps={{
          slidesPerView: 'auto',
          spaceBetween: 8,
          navigation: {
            nextEl: `.filters-${componentIndex}-custom-right`,
            prevEl: `.filters-${componentIndex}-custom-left`,
            disabledClass: 'swiper-button-disabled',
          },
          fadelast: 'hide',
        }}
      >
        {categories?.map((category, index) => (
          <Link key={index} href={category.link || ''}>
            <button
              className={
                'rounded-lg bg-white px-4 py-3 text-xs font-bold text-gray-dark shadow-sm'
              }
              style={{ background: itemBackgroundColor, color: itemLabelText }}
            >
              {category.label}
            </button>
          </Link>
        ))}
      </BaseSwiper>
      {categories && categories.length > 8 && (
        <>
          <button
            className={clsx(
              `filters-${componentIndex}-custom-left swiper-button-prev custom-filter-swiper-button-prev`
            )}
          ></button>
          <button
            className={clsx(
              `filters-${componentIndex}-custom-right swiper-button-next custom-filter-swiper-button-next`
            )}
          ></button>
        </>
      )}
    </div>
  );
}
