'use client';

import { ReactNode } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearchNext } from 'react-instantsearch-nextjs';
import { history } from 'instantsearch.js/es/lib/routers';
import singleIndexStateMapping from 'instantsearch.js/es/lib/stateMappings/singleIndex';
import { createFetchRequester } from '@algolia/requester-fetch';
import VirtualWidgets from 'components/virtual-widgets';
import { Configure } from 'react-instantsearch';

import type { AlgoliaConfig } from 'types/algolia';

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
  {
    requester: createFetchRequester(),
  }
);

export default function InstantSearchProvider({
  children,
  baseUrl,
  algoliaConfig,
  algoliaIndexName,
  magentoId,
}: {
  children: ReactNode;
  baseUrl: string;
  algoliaConfig: AlgoliaConfig | undefined;
  algoliaIndexName: string;
  magentoId?: string;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  if (!algoliaConfig) {
    return <>{children}</>;
  }

  const routing = {
    router: history({
      // In this context, URL and Location is interchangeable.
      // Algolia only needs the 'search' property of the location.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getLocation() {
        if (typeof window === 'undefined') {
          const url = `${baseUrl}${pathname}?${searchParams}`; // eslint-disable-line @typescript-eslint/restrict-template-expressions
          return new URL(url);
        }

        return window.location;
      },
      cleanUrlOnDispose: false,
    }),
    stateMapping: singleIndexStateMapping(algoliaIndexName),
  };

  return (
    <InstantSearchNext
      indexName={algoliaIndexName}
      searchClient={searchClient}
      future={{ preserveSharedStateOnUnmount: true }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      routing={routing}
    >
      {children}
      <VirtualWidgets
        algoliaConfig={algoliaConfig}
        algoliaIndexName={algoliaIndexName}
      />
      {magentoId && (
        <Configure enablePersonalization={true} userToken={magentoId} />
      )}
      {!magentoId && <Configure enablePersonalization={false} />}
    </InstantSearchNext>
  );
}
