import React from 'react';

import { useInfiniteHits } from 'react-instantsearch';
import Hitscontainer from './hits-container';
import { useSentinel } from './hook';
import Loading from './loading';

export default function MobileHit(props: any) {
  const {
    hits,
    isLastPage,
    showMore,
    sendEvent,
    currentPageHits,
    isFirstPage,
  } = useInfiniteHits(props);
  const productHits = props.isBundleLayout ? props.products : hits;
  const { sentinelRef } = useSentinel(isLastPage, productHits.length, showMore);

  return (
    <Hitscontainer
      dualColumn={props.dualColumn}
      isBundleLayout={props.isBundleLayout}
      products={props.products}
      inGridBannerComponentData={props.inGridBannerComponentData}
      getProducts={props.getProducts}
      setProducts={props.setProducts}
      hits={hits}
      currentPageHits={currentPageHits}
      isFirstPage={isFirstPage}
      productItemsSettingsData={props.productItemsSettingsData}
      isMobileSearchModal={props.isMobileSearchModal}
      sendEvent={sendEvent}
      language={props.language}
      region={props.region}
    >
      {!props.isMobileSearchModal && (
        <div ref={sentinelRef} aria-hidden='true' />
      )}
      <Loading />
    </Hitscontainer>
  );
}
