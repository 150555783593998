import { getProductsRatings } from 'lib/bazaarvoice';
import { getProductsTile } from 'lib/nahdi-middleware';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppSelector } from 'redux/hooks';
import { disableGlobalConfig } from 'utils/disable-global-config';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_ZONE_ID } from 'utils/constant-cookies';

export const useHitsModifier = (
  productHits: any[],
  isBundleLayout: boolean,
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const [promoBadgeMessage, setPromoBadgeMessage] = useState();
  const [alternativeProducts, setAlternativeProducts] = useState();
  const [outStock, setOutStock] = useState<boolean[]>([]);
  const [hasBundleOffer, setHasBundleOffer] = useState<boolean[]>([]);
  const [bundleSkus, setBundleSkus] = useState();
  const [rating, setRating] = useState<any>();
  const [shipmentTypes, setShipmentTypes] = useState<
    Record<string, any | undefined>
  >({});

  const [cookies] = useCookies([COOKIE_ZONE_ID]);
  const zoneIdCookie = cookies?.zoneId || '';

  const { appSettings } = useAppSelector((state) => state.cmsReducer);

  const bazaarvoiceEnable = disableGlobalConfig({
    settings: appSettings?.functionalityConfig?.bazaarVoiceSettings,
  });

  const fetchProductData = useCallback(async () => {
    const productIds = productHits?.map((hit: { sku: any }) => hit.sku);
    const productsData = (await getProductsTile(
      productIds.join(','),
      zoneIdCookie,
      language,
      region
    )) as any[];

    const badgesMap = productsData.reduce((acc: any, product: any) => {
      acc[product.product_sku] = product.promo_badge;
      return acc;
    }, {});

    const stockMap = productsData.reduce((acc: any, product: any) => {
      acc[product.product_sku] = product.in_stock;
      return acc;
    }, {});

    const alternativeProductMap = productsData.reduce(
      (acc: any, product: any) => {
        acc[product.product_sku] = product.alternative_products;
        return acc;
      },
      {}
    );

    const hasBundleOfferMap = productsData.reduce((acc: any, product: any) => {
      acc[product.product_sku] = product.has_bundle_offer;
      return acc;
    }, {});

    const bundleSkusMap = productsData.reduce((acc: any, product: any) => {
      acc[product.product_sku] = product.bundle_skus;
      return acc;
    }, {});
    const shipmentTypes = productsData.reduce((acc: any, product: any) => {
      acc[product.product_sku] = product.shipment_type;
      return acc;
    }, {});

    setShipmentTypes(shipmentTypes);
    setPromoBadgeMessage(badgesMap);
    setAlternativeProducts(alternativeProductMap);
    setOutStock(stockMap);
    setHasBundleOffer(hasBundleOfferMap);
    setBundleSkus(bundleSkusMap);
  }, [language, productHits, region, zoneIdCookie]);

  const fetchRatingData = useCallback(async () => {
    const productIds = productHits?.map((hit: { sku: any }) => hit.sku);

    const ratingsData = bazaarvoiceEnable
      ? await getProductsRatings(productIds as any, language, region)
      : {};
    const ratingsMap = productIds.reduce((acc: any, id: any) => {
      acc[id] = ratingsData ? ratingsData[id] : {};
      return acc;
    }, {});

    setRating(ratingsMap);
  }, [bazaarvoiceEnable, language, productHits, region]);

  useEffect(() => {
    if (productHits?.length > 0 && !isBundleLayout) {
      fetchProductData();
      fetchRatingData();
    }
  }, [fetchProductData, fetchRatingData, isBundleLayout, productHits?.length]);

  return {
    zoneIdCookie,
    promoBadgeMessage,
    alternativeProducts,
    outStock,
    hasBundleOffer,
    bundleSkus,
    rating,
    shipmentTypes,
  };
};
