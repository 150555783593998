import CmsHeroBanner from 'components/cms/banners/hero-banner/home-hero-banner';
import React from 'react';
import Hit from './hit';
import { useHitsModifier, useProductUpdater, useBannerInjector } from './hook';
import { AlgoliaHit, BaseHit } from 'instantsearch.js';
import { BundleProductInfoType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { useAppSelector } from 'redux/hooks';

interface HitContainerProps {
  children?: React.ReactNode;
  dualColumn: boolean;
  isBundleLayout: boolean;
  products: any[];
  inGridBannerComponentData: any;
  getProducts: (ids: string[]) => BundleProductInfoType[];
  setProducts: (products: BundleProductInfoType[]) => void;
  hits: AlgoliaHit<BaseHit>[];
  currentPageHits: AlgoliaHit<BaseHit>[];
  isFirstPage: boolean;
  productItemsSettingsData: any;
  isMobileSearchModal: boolean;
  sendEvent: (...args: any[]) => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}

export default function Hitscontainer({
  children,
  dualColumn,
  isBundleLayout,
  products,
  inGridBannerComponentData,
  hits,
  setProducts,
  currentPageHits,
  productItemsSettingsData,
  isMobileSearchModal,
  sendEvent,
  language,
  region,
}: HitContainerProps) {
  const productHits = isBundleLayout ? products : hits;

  const { deliveryMode } = useAppSelector((state) => state.customerReducer);
  const breakPoint = productItemsSettingsData?.plp_interruptor_break;

  const isStorePickup = deliveryMode?.mode === 'pickup';

  const isProductDay = (
    redbox_pl_custom: string,
    productLabelStartDate: string | number | Date,
    productLabelEndDate: string | number | Date
  ) => {
    if (redbox_pl_custom === 'No') {
      return false;
    }
    const currentDate = new Date();
    const startDate = new Date(productLabelStartDate);
    const endDate = new Date(productLabelEndDate);

    if (currentDate >= startDate && currentDate <= endDate) {
      return true;
    }
    return false;
  };

  const {
    zoneIdCookie,
    promoBadgeMessage,
    alternativeProducts,
    outStock,
    hasBundleOffer,
    rating,
    shipmentTypes,
  } = useHitsModifier(productHits, isBundleLayout, language, region);

  useProductUpdater(
    currentPageHits,
    products,
    setProducts,
    hits,
    isBundleLayout,
    language,
    region
  );

  const { containerRef, ingridBannerRef } = useBannerInjector(
    dualColumn,
    breakPoint
  );

  return (
    <>
      {inGridBannerComponentData && (
        <div className='hidden'>
          <div
            ref={ingridBannerRef}
            id='ingrid-variation-banner'
            className={`-mx-4 lg:mx-0 ${
              dualColumn
                ? 'lg:col-span-2'
                : 'col-span-2 lg:col-span-3 xl:col-span-4'
            } `}
          >
            <div style={{ marginTop: '50px', marginBottom: '50px' }}>
              <CmsHeroBanner
                data={inGridBannerComponentData}
                disableFadeEffect
                language={language}
                region={region}
              />
            </div>
          </div>
        </div>
      )}

      {!isBundleLayout ? (
        <div
          ref={containerRef}
          id='search-results-container'
          className={`grid gap-x-2 gap-y-4 ${
            dualColumn
              ? 'lg:grid-cols-2'
              : 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
          } lg:gap-4`}
        >
          {(!isMobileSearchModal ? productHits : productHits.slice(0, 6)).map(
            (hit) => (
              <div
                key={`product-item-${hit.sku}`}
                className={`${
                  isProductDay(
                    hit?.redbox_pl_custom,
                    hit?.redbox_pl_start_date,
                    hit?.redbox_pl_end_date
                  )
                    ? 'col-span-2'
                    : ''
                }`}
                onClick={() => {
                  sendEvent('click:internal', hit, 'Hit Clicked');
                }}
                onAuxClick={() => {
                  sendEvent('click:internal', hit, 'Hit Clicked');
                }}
              >
                <Hit
                  hit={hit}
                  dualColumn={dualColumn}
                  zoneId={zoneIdCookie}
                  promoBadgeMessage={
                    promoBadgeMessage ? promoBadgeMessage[hit.sku] : ''
                  }
                  alternativeProducts={
                    alternativeProducts ? alternativeProducts[hit.sku] : []
                  }
                  rates={{
                    avgRating: rating ? rating[hit.sku]?.avgRating || 0 : 0,
                    totalRatings: rating ? rating[hit.sku]?.total || 0 : 0,
                  }}
                  inStock={outStock ? outStock[hit.sku] : false}
                  isStorePickup={isStorePickup}
                  hasBundleOffer={hasBundleOffer[hit.sku] as boolean}
                  shipmentType={shipmentTypes[hit.sku]}
                  isProductDay={isProductDay(
                    hit?.redbox_pl_custom,
                    hit?.redbox_pl_start_date,
                    hit?.redbox_pl_end_date
                  )}
                  language={language}
                  region={region}
                />
              </div>
            )
          )}
        </div>
      ) : (
        <div
          className={'grid grid-cols-1 gap-x-2 gap-y-4 lg:grid-cols-2 lg:gap-4'}
        >
          {products?.map((hit: any) => (
            <div
              key={`product-item-${hit.sku_id}`}
              onClick={() => {
                sendEvent('click:internal', hit, 'Hit Clicked');
              }}
              onAuxClick={() => {
                sendEvent('click:internal', hit, 'Hit Clicked');
              }}
            >
              <Hit
                hit={hit}
                zoneId={zoneIdCookie}
                promoBadgeMessage={
                  promoBadgeMessage ? promoBadgeMessage[hit.sku] : ''
                }
                rates={rating ? rating[hit.sku] : undefined}
                inStock={outStock ? outStock[hit.sku] : false}
                isStorePickup={isStorePickup}
                isBundle={true}
                shipmentType={shipmentTypes[hit.sku]}
                language={language}
                region={region}
              />
            </div>
          ))}
        </div>
      )}
      {children}
    </>
  );
}
