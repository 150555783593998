'use client';

import NoSearchResult from 'components/search/no-search-results';
import TrendingKeywords from 'components/search/trending-keywords';
import { RefObject, useState } from 'react';
import RecentSearches from './search/recent-searches';
import { PLPContentType } from 'types/cms/plp-content';
import { SearchBoxWithSuggestions } from './search/suggestion-box';
import CategoriesCarousel from './cms/categories/categories-carousel';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';
import HydrationWrapper from './hydration-wrapper';

export default function MiniSearch({
  openMiniSearch,
  plpContentData,
  miniSearchRef,
  inputValue,
  onClickSuggestion,
  searchAsYouType,
  language,
  region,
}: {
  openMiniSearch: boolean;
  plpContentData: PLPContentType;
  miniSearchRef: RefObject<HTMLDivElement>;
  inputValue: string;
  onClickSuggestion: (value: string) => void;
  searchAsYouType: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isSuggestionsEmpty, setIsSuggestionsEmpty] = useState(true);

  return (
    <div
      ref={miniSearchRef}
      className={clsx([
        'absolute left-0 top-16 z-[99] w-full lg:px-6',
        openMiniSearch && !searchAsYouType ? 'block' : 'hidden',
      ])}
    >
      <span className='absolute -top-1.5 left-11 h-2.5 w-2.5 rotate-45 bg-white'></span>
      <div
        className={clsx([
          'overflow-hidden rounded-md bg-white p-4',
          !isSuggestionsEmpty && inputValue ? 'block' : 'hidden',
        ])}
      >
        <SearchBoxWithSuggestions
          onClickSuggestion={(value: string) => onClickSuggestion(value)}
          onCheckIsSuggestionsEmpty={(value) => setIsSuggestionsEmpty(value)}
          inputValue={inputValue}
          language={language}
          region={region}
        />
      </div>
      <div
        className={clsx([
          'flex flex-col gap-8 overflow-hidden rounded-md bg-white p-4',
          inputValue ? 'hidden' : 'block',
        ])}
      >
        <HydrationWrapper>
          <TrendingKeywords
            tredingkeywordsData={plpContentData?.trendingSearches}
            onClickSuggestion={(value: string) => {
              onClickSuggestion(value);
            }}
          />
        </HydrationWrapper>

        <RecentSearches
          onClickSuggestion={(value: string) => onClickSuggestion(value)}
          language={language}
          region={region}
        />
        {plpContentData?.trendingCategories && (
          <CategoriesCarousel
            componentIndex={100}
            data={{
              ...plpContentData?.trendingCategories,
              title_class: '!text-custom-lg',
            }}
            language={language}
            region={region}
          />
        )}
      </div>
      <div
        className={clsx([
          'overflow-hidden rounded-md bg-white p-4',
          inputValue && isSuggestionsEmpty ? 'block' : 'hidden',
        ])}
      >
        <HydrationWrapper>
          <NoSearchResult
            isMiniSearchWindow={true}
            language={language}
            region={region}
          />
        </HydrationWrapper>
      </div>
    </div>
  );
}
