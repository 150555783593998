import { createSlice } from '@reduxjs/toolkit';
import { NoSearchResultType } from 'types/cms/no-search-result';
import { ProductItemType } from 'types/cms/product-carousel';
type NoSearchResultState = {
  noSearchResultData: NoSearchResultType | null;
  noSearchResultSpecialDealProducts: ProductItemType[] | null;
};
const initialState: NoSearchResultState = {
  noSearchResultData: null,
  noSearchResultSpecialDealProducts: null,
};
export const NoSearchResultData = createSlice({
  name: 'noSearchResultData',
  initialState,
  reducers: {
    setnoSearchResultData: (state, action) => {
      state.noSearchResultData = action.payload;
    },
    setNoSearchResultSpecialDealProducts: (state, action) => {
      state.noSearchResultSpecialDealProducts = action.payload;
    },
  },
});
export const { setnoSearchResultData, setNoSearchResultSpecialDealProducts } =
  NoSearchResultData.actions;
export default NoSearchResultData.reducer;
