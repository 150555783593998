import BundleItem from 'components/bundle-item';
import ProductItem from 'components/product-item';
import type { Hit as AlgoliaHit } from 'instantsearch.js';
import React from 'react';
import { CartShipmentType } from 'types/my-cart';
import { AlgoliaProduct, BundleProductInfoType } from 'types/product';
import { hitToProduct } from 'utils/algolia';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function Hit({
  hit,
  dualColumn,
  zoneId,
  promoBadgeMessage,
  alternativeProducts,
  rates,
  inStock,
  isStorePickup,
  hasBundleOffer,
  isBundle,
  shipmentType,
  isProductDay,
  language,
  region,
}: {
  hit: AlgoliaHit | BundleProductInfoType;
  dualColumn?: boolean;
  promoBadgeMessage?: string;
  alternativeProducts?: [];
  inStock?: boolean;
  rates: {
    avgRating: number;
    totalRatings: number;
  };
  zoneId: string;
  isStorePickup: boolean;
  hasBundleOffer?: boolean;
  isBundle?: boolean;
  shipmentType: CartShipmentType;
  isProductDay?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const product = isBundle ? hit : hitToProduct(hit as AlgoliaHit);

  return (
    <>
      {!isBundle ? (
        <ProductItem
          product={product as AlgoliaProduct}
          dualColumn={dualColumn}
          promoBadgeMessage={promoBadgeMessage || ''}
          alternativeProducts={alternativeProducts || []}
          rates={rates}
          inStock={inStock}
          zoneId={zoneId}
          isStorePickup={isStorePickup}
          hasBundleOffer={hasBundleOffer}
          shipmentType={shipmentType}
          isProductDay={isProductDay}
          language={language}
          region={region}
        />
      ) : (
        <BundleItem
          product={product as BundleProductInfoType}
          containerClasses={'relative rounded-lg bg-white p-3 pb-4 shadow-sm'}
          language={language}
          region={region}
        />
      )}
    </>
  );
}
