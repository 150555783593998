'use client';

import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'app/i18n/client';
import Image from 'next/image';
import { Link } from 'components/link';
import { Checkbox } from './checkbox';
import { BundleItemType, BundleProductItemType } from 'types/product';
import { convertToAbsoluteURL, getPdpUrl } from 'utils';
import BaseSwiper from 'components/swiper/base-swiper';
import { BundleAddCart } from 'components/product/bundle-offer/add-to-cart';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function BundleItem({
  product,
  isBundleOffer = true,
  showCheckbox,
  showAddToCart = true,
  containerClasses = '',
  language,
  region,
}: BundleItemType & {
  containerClasses: string;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-detail-page'
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (product?.items?.length > 0) {
      setIsLoading(false);
    }
  }, [product]);

  const worthPrice = product?.regular_price;
  const nowPrice = product?.new_price;
  const discount = product?.discount;
  const currency = product?.items?.[0]?.attributes?.currency;

  if (!product || product?.items?.length === 0) return;

  return (
    <>
      {!isLoading ? (
        <div className={`${containerClasses} js-bundle-product`}>
          {isBundleOffer && (
            <div className='relative'>
              <div className='absolute z-10'>
                <Image
                  src='/assets/images/product_tags/en/bundle_offer.png'
                  alt={t('bundle_offer') as string}
                  width={84}
                  height={32}
                  className='rounded-lg object-cover'
                />
              </div>
            </div>
          )}
          <div className='mt-2 flex flex-col items-start space-y-2'>
            <div className='flex w-full items-center justify-center space-x-4'>
              <BaseSwiper
                swiperProps={{
                  slidesPerView:
                    product?.items?.length > 2 ? 2 : product?.items?.length,
                  spaceBetween: 60,
                  navigation: true,
                  fadelast: product?.items?.length > 2 ? 'show' : 'hide',
                }}
              >
                {product?.items?.map(
                  (bundleProductItem: BundleProductItemType, index: number) => (
                    <React.Fragment key={`bundle-item-product-${index}`}>
                      <div className='relative'>
                        {showCheckbox && <Checkbox checked={true} />}
                        <Link
                          href={getPdpUrl(
                            bundleProductItem.sku_id,
                            bundleProductItem.attributes
                              ?.calculated_relative_url,
                            language,
                            region
                          )}
                        >
                          <div className='relative flex justify-center'>
                            <Image
                              src={convertToAbsoluteURL(
                                bundleProductItem?.attributes?.media_gallery
                                  ?.types?.medium
                              )}
                              alt={bundleProductItem.attributes?.name}
                              width={131}
                              height={137}
                              className='mx-auto h-[137px] w-[131px]'
                            />
                            {/* TODO: Check if it's a wholesale like x12 and show this section. Also send that quantity while adding it to the cart. */}
                            {/* {product.bundleAmount && (
                      <div
                        className={clsx(
                          'absolute bottom-[-10.5px] h-[21px] rounded-full px-2 py-1',
                          'bg-primary-red text-[11px] font-bold text-white',
                          'flex items-center justify-center'
                        )}
                      >
                        {t('bundle.productCount', {
                          count: product.bundleAmount,
                        })}
                      </div>
                    )} */}
                          </div>
                          <span className='mt-3 line-clamp-2 h-8 text-start text-[13px] leading-[1.3] text-gray-dark lg:text-center'>
                            {bundleProductItem.attributes?.name}
                          </span>
                          <span className='mt-2 line-clamp-2 h-4 text-start text-[13px] font-semibold leading-[1.3] text-gray-dark lg:text-center'>
                            {bundleProductItem.attributes?.price} {currency}
                          </span>
                        </Link>
                      </div>
                      {index < product?.items?.length - 1 &&
                        product?.items?.length > 1 && (
                          <div className='absolute top-[30%] translate-y-1/2 ltr:-right-12 rtl:-left-12'>
                            <PlusIcon className='w-6 fill-primary-blue stroke-2' />
                          </div>
                        )}
                    </React.Fragment>
                  )
                )}
              </BaseSwiper>
            </div>
            <div className='h-[1px] w-full bg-black opacity-[0.05]' />
            <div className='flex w-full flex-col items-center space-x-1 rtl:space-x-reverse'>
              {worthPrice && (
                <span className='text-[15px] leading-[1.3] text-light-gray lg:text-gray'>
                  {t('bundle.worth')} {parseFloat(worthPrice).toFixed(2)}{' '}
                  {currency}
                </span>
              )}
              {nowPrice && (
                <span className='mt-1 text-[20px] font-semibold leading-[1.3] text-primary-red'>
                  {t('bundle.now')} {parseFloat(nowPrice).toFixed(2)} {currency}
                </span>
              )}
              {discount && (
                <span className='mt-2 rounded-full border border-dashed border-primary-red bg-primary-red/[0.1] px-2 py-1 font-semibold leading-[1.3] text-primary-red max-lg:text-custom-xs lg:text-custom-lg'>
                  {t('bundle.save')} {parseFloat(discount)} {currency}
                </span>
              )}
            </div>
          </div>
          {showAddToCart && (
            <div className='absolute drop-shadow-md max-lg:bottom-2 lg:bottom-3 max-lg:ltr:right-2 lg:ltr:right-3 max-lg:rtl:left-2 lg:rtl:left-3'>
              <BundleAddCart
                products={product?.items}
                buttonTypePlus
                language={language}
                region={region}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            'h-[340px] animate-pulse rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm'
          }
        ></div>
      )}
    </>
  );
}
