'use client';

import React from 'react';
import { CallPharmacist } from 'components/icon';
import { useTranslation } from 'app/i18n/client';
import { useSearchBox } from 'react-instantsearch';
import ArrowRight from 'components/icons/arrow-right';
import clsx from 'clsx';
import ClientSpecialDeals from './client-special-deals';
import CategoriesCarousel from 'components/cms/categories/categories-carousel';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppSelector } from 'redux/hooks';
import ContentfulLink from 'components/contentful-link';

function NoSearchResult({
  isMiniSearchWindow = false,
  language,
  region,
}: {
  isMiniSearchWindow?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { query } = useSearchBox();
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'search'
  );
  const noSearchResultData = useAppSelector(
    (state) => state.noSearchResultReducer.noSearchResultData
  );
  const windowIsMobileSize = useMediaQuery(mobileSize);

  if (!noSearchResultData) return;

  return (
    <div className={clsx(['bg-light-gray-200 pt-6 lg:bg-none lg:pt-0'])}>
      {!isMiniSearchWindow && (
        <p
          className={clsx([
            'flex gap-2 tracking-tight',
            'container-base text-custom-lg lg:mt-6 lg:text-[24px]',
          ])}
          style={{ color: noSearchResultData?.colorTheme?.header_text_color }}
        >
          {noSearchResultData?.header}{' '}
          <span className='hidden lg:block'>
            {!isMiniSearchWindow && <>&quot;{query}&quot; </>}
          </span>
        </p>
      )}
      <div className='container-base mb-8 lg:mb-[52px]'>
        <div
          className={clsx(
            'justify-between text-custom-sm text-gray shadow-standart md:flex',
            isMiniSearchWindow ? 'items-start' : 'mt-4 items-center p-4'
          )}
          style={{
            background:
              noSearchResultData?.colorTheme?.tips_box_background_color,
            color: noSearchResultData?.colorTheme?.tips_text_color,
          }}
        >
          <div>
            {isMiniSearchWindow && (
              <p
                className={clsx([
                  'tracking-tight text-black',
                  'mb-4 text-custom-lg',
                ])}
              >
                {noSearchResultData?.header}{' '}
                {!isMiniSearchWindow && !windowIsMobileSize && (
                  <>&quot;{query}&quot; </>
                )}
              </p>
            )}
            <p className='font-semibold'>{noSearchResultData?.tips}</p>
            <ul className='list-disc gap-4 ltr:ml-[14px] rtl:mr-[14px]'>
              {noSearchResultData?.searchTips?.map((searchTipItem, index) => (
                <li key={(searchTipItem?.label ?? '') + index} className='mt-3'>
                  {searchTipItem.label}
                </li>
              ))}
            </ul>
          </div>

          {isMiniSearchWindow ? (
            <div className='flex flex-col gap-3'>
              <p className='text-[12px] font-semibold'>{ts('need_help')}</p>
              <ContentfulLink
                item={noSearchResultData?.callPharmacistCTA}
                className='hidden h-[45px] cursor-pointer items-center gap-4 rounded-md border border-primary bg-white p-3 font-semibold md:flex'
                style={{
                  background:
                    noSearchResultData?.colorTheme
                      ?.call_pharmacist_cta_background_color,
                  color:
                    noSearchResultData?.colorTheme
                      ?.call_pharmacist_cta_text_color,
                }}
                language={language}
                region={region}
              >
                <CallPharmacist language={language} region={region} />
                <p>{noSearchResultData?.callPharmacistCTA?.label}</p>
                <ArrowRight className='ltr:rotate-0 rtl:rotate-180' />
              </ContentfulLink>
            </div>
          ) : (
            <ContentfulLink
              style={{
                background:
                  noSearchResultData?.colorTheme
                    ?.call_pharmacist_cta_background_color,
                color:
                  noSearchResultData?.colorTheme
                    ?.call_pharmacist_cta_text_color,
              }}
              item={noSearchResultData?.callPharmacistCTA}
              className='hidden h-[45px] cursor-pointer items-center gap-4 rounded-md border border-primary bg-white p-3 font-semibold md:flex'
              language={language}
              region={region}
            >
              <CallPharmacist language={language} region={region} />
              <p>{noSearchResultData?.callPharmacistCTA?.label}</p>
              <ArrowRight className='ltr:rotate-0 rtl:rotate-180' />
            </ContentfulLink>
          )}
        </div>
        <ContentfulLink
          style={{
            background:
              noSearchResultData?.colorTheme
                ?.call_pharmacist_cta_background_color,
            color:
              noSearchResultData?.colorTheme?.call_pharmacist_cta_text_color,
          }}
          item={noSearchResultData?.callPharmacistCTA}
          className='mt-4 flex cursor-pointer items-center justify-between rounded-md border border-primary bg-white p-3 font-semibold md:hidden'
          language={language}
          region={region}
        >
          <div className='flex items-center gap-4'>
            <CallPharmacist language={language} region={region} />
            <p>{noSearchResultData?.callPharmacistCTA?.label}</p>
          </div>
          <ArrowRight className='ltr:rotate-0 rtl:rotate-180' />
        </ContentfulLink>
      </div>
      {!isMiniSearchWindow && (
        <ClientSpecialDeals
          data={{
            ...noSearchResultData?.productCarouselData,
            titleClass: '!max-w-full !overflow-visible',
          }}
          language={language}
          region={region}
        />
      )}
      {noSearchResultData?.trendingCategoriesData && (
        <div className='mt-8'>
          <CategoriesCarousel
            componentIndex={101}
            data={{
              ...noSearchResultData.trendingCategoriesData,
              title_class: isMiniSearchWindow ? '!text-custom-lg' : '',
            }}
            language={language}
            region={region}
          />
        </div>
      )}
    </div>
  );
}

export default NoSearchResult;
