'use client';

import ProductCarousel from 'components/cms/banners/product-carousel-banner/product-carousel';
import HomeBannersLayout from 'components/layout/homepage/home-banners-layout';
import RecommedationFilters from 'components/recommendations/recommedation-filters';
import { getProductsByProductIds } from 'lib/api';
import React, { useEffect } from 'react';
import { setNoSearchResultSpecialDealProducts } from 'redux/features/no-search-result';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { HomePageProductCarouselType } from 'types/cms/product-carousel';
import { LanguageCodesType, RegionCodesType } from 'utils';

function ClientSpecialDeals({
  data,
  language,
  region,
}: {
  data: HomePageProductCarouselType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();
  const productsData = useAppSelector(
    (state) => state.noSearchResultReducer.noSearchResultSpecialDealProducts
  );
  useEffect(() => {
    if (!data || productsData || !data?.product_id_list) return;

    const handleGetSpecialDeals = async () => {
      const products = await getProductsByProductIds(
        data.product_id_list,
        language,
        region
      );
      dispatch(setNoSearchResultSpecialDealProducts(products));
    };
    handleGetSpecialDeals();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, productsData]);

  if (!data) return;
  return (
    <HomeBannersLayout
      title={data.header}
      showAllLink={data.link}
      showAll={data.label}
      backgroundColor={data.color_theme?.background_color}
      titleColor={data.color_theme?.header_text_color}
      showAllLinkColor={data.color_theme?.cta_show_all_label_text_color}
      titleClass={data.titleClass}
    >
      {data?.category_list?.items.length > 0 && (
        <RecommedationFilters
          categories={data?.category_list?.items}
          itemBackgroundColor={
            data.color_theme?.category_list_item_background_color
          }
        />
      )}
      <ProductCarousel
        swiper={productsData?.length! > 5}
        componentIndex={120}
        algoliaType={data.data_source === 'ALGOLIA' ? true : false}
        products={productsData!}
        fadeLast={data?.color_theme?.slider_fade_effect}
        language={language}
        region={region}
      />
    </HomeBannersLayout>
  );
}
export default ClientSpecialDeals;
