import { useEffect, useRef } from 'react';

export const useSentinel = (
  isLastPage: boolean,
  hitsLength: number,
  showMore: () => void
) => {
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !isLastPage &&
            hitsLength !== 0 &&
            typeof showMore === 'function'
          ) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, hitsLength, showMore]);
  return { sentinelRef };
};
