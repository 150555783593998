'use client';

import { GoBackIcon, SearchIcon } from 'components/icon';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useClearRefinements, useSearchBox } from 'react-instantsearch';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import MiniSearch from './mini-search-window';
import { setSearchQuery } from 'redux/features/searchQuerySlice';
import useRouterAsync from 'hooks/use-router-async';
import { useDebounceFunc } from 'hooks/use-debounce';
import { SearchBarInput } from 'components/search-bar-input';
import { setRecentSearchesLocalStorage } from 'utils';
import MobileSearchModal from './search/mobile-search-modal';
import ReactPortal from './react-portal';
import { SearchBoxWithSuggestions } from './search/suggestion-box';
import { AlgoliaConfig } from 'types/algolia';
import { mobileSize, useMediaQuery, desktopSize } from 'hooks/use-media-query';
import SearchClear from './icons/search-clear';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export default function SearchBar({
  searchAsYouType,
  isSearchPage = false,
  textColor,
  placeholder,
  multiPlaceholder,
  language,
  region,
}: {
  searchAsYouType: boolean;
  isSearchPage?: boolean;
  textColor?: string;
  placeholder?: string;
  multiPlaceholder?: any;
  algoliaConfig?: AlgoliaConfig;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'search'
  );

  const asyncRouter = useRouterAsync();
  const pathname = usePathname();
  const isDesktop = useMediaQuery(desktopSize);

  const plpContentData = useAppSelector(
    (state) => state.plpContentReducer.plpContentData
  );
  const dispatch = useAppDispatch();

  const { query, refine } = useSearchBox();
  const { refine: clearRefinements } = useClearRefinements();
  const searchParams = useSearchParams();
  const params = useParams<{ handle: string }>();
  const debounce = useDebounceFunc();

  const [inputValue, setInputValue] = useState(query);
  const [isSuggestionsEmpty, setIsSuggestionsEmpty] = useState(true);
  const [hideSuggestions, setHideSuggestions] = useState(true);

  const [openMiniSearch, setOpenMiniSearch] = useState(false);
  const [isMobileSearchModalOpen, setIsMobileSearchModalOpen] = useState(false);
  const isMobile = useMediaQuery(mobileSize);

  const inputRef = useRef<HTMLInputElement>(null);
  const miniSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current?.contains(event.target as Node) && openMiniSearch) {
        return;
      }
      if (!miniSearchRef.current?.contains(event.target as Node)) {
        setOpenMiniSearch(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    dispatch(setSearchQuery(newQuery));
    if (searchAsYouType && pathname?.includes('/search')) {
      debounce(() => {
        refineAndSetRecentSearch(newQuery);
      }, 200);
    }
  }

  function refineAndSetRecentSearch(newQuery: string) {
    refine(newQuery);
    setRecentSearchesLocalStorage(newQuery);
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    setHideSuggestions(true);
    dispatch(setSearchQuery(inputValue));

    if (!pathname?.includes('/search'))
      await asyncRouter.push(`/search?query=${inputValue}`);
    if (!searchAsYouType) refineAndSetRecentSearch(inputValue);

    if (inputRef.current) {
      inputRef.current.blur();
    }

    setOpenMiniSearch(false);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.currentTarget.value.length > 0 ? e.currentTarget.value : '');
    if (hideSuggestions) {
      setHideSuggestions(false);
    }
  }

  async function onClick() {
    searchAsYouType
      ? query
        ? null
        : await asyncRouter.push('/search')
      : isMobile
      ? (setIsMobileSearchModalOpen(true), setHideSuggestions(true))
      : setOpenMiniSearch(true);
  }

  // Track when the InstantSearch query changes to synchronize it with
  // the React state.
  // We bypass the state update if the input is focused to avoid concurrent
  // updates when typing.

  // If we changed from /search to any other page, reset the query
  useEffect(() => {
    if (!pathname?.includes('/search') && !isMobileSearchModalOpen) {
      setInputValue('');
      refine('');
      clearRefinements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, refine, clearRefinements]);

  useEffect(() => {
    if (
      !pathname?.includes('/search') &&
      searchParams.get('query') &&
      isMobile
    ) {
      setIsMobileSearchModalOpen(true);
    }
  }, [pathname, isMobile, searchParams]);

  useEffect(() => {
    if (params?.handle && isMobile) {
      setIsMobileSearchModalOpen(false);
    }
  }, [params, isMobile]);

  useEffect(() => {
    if (
      searchParams.get('query') &&
      searchParams.get('query') !== inputValue &&
      document.activeElement !== inputRef.current &&
      !isMobileSearchModalOpen
    ) {
      setInputValue(searchParams.get('query')!);
      refine(searchParams.get('query')!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMobileSearchModalOpen ? 'hidden' : 'auto';
  }, [isMobileSearchModalOpen]);

  return isSearchPage && isMobile ? (
    <div className='flex w-full flex-col'>
      <div className={'container-base h-16 w-full bg-white p-4'}>
        <div className='flex h-full w-full items-center justify-between gap-3 ltr:flex-row rtl:flex-row-reverse'>
          <GoBackIcon
            onClick={async () => {
              await asyncRouter.push('/');
              refine('');
              clearRefinements();
            }}
            className='has-color fill-current text-blue'
          />
          <form
            action=''
            onSubmit={(e) => {
              onSubmit(e);
            }}
            noValidate
            className='relative w-[90%] lg:w-full lg:px-6'
            autoComplete='off'
            role='search'
          >
            <div className='absolute top-3 flex items-center lg:top-3 ltr:left-4 ltr:lg:left-10 rtl:right-4 lg:rtl:right-10'>
              <SearchIcon
                className='has-color fill-current text-gray lg:text-white'
                style={{ fill: isDesktop ? textColor : 'text-gray' }}
              />
            </div>
            {inputRef.current?.value && (
              <button
                type='button'
                onClick={() => {
                  setInputValue('');
                  refine('');
                  clearRefinements();
                }}
                className='absolute end-4 top-3 flex h-6 w-6 lg:end-10'
              >
                <SearchClear />
              </button>
            )}
            <input
              type='search'
              name='search'
              placeholder={placeholder || ts('search')}
              className={clsx(
                'headerSearchPlaceholder | h-11 w-full rounded-lg py-2 text-[16px] text-gray-600 lg:h-12 lg:px-12 lg:text-white ltr:pl-11 rtl:pr-11',
                'border border-light-gray bg-white focus:!outline-transparent focus:!ring-transparent focus:!ring-offset-0 lg:border-2 lg:border-transparent lg:bg-blue-light lg:focus:bg-blue-light',
                'placeholder:text-gray lg:placeholder:text-gray-200'
              )}
              style={{ color: isDesktop ? textColor : 'text-gray' }}
              aria-label='Search'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              value={inputValue}
              onChange={onChange}
              onClick={() => {
                onClick();
              }}
              onFocus={() => setHideSuggestions(false)}
            />
            <style jsx>{`
              .headerSearchPlaceholder::placeholder {
                color: ${isDesktop ? textColor : 'text-gray'};
              }
            `}</style>
            {isMobile &&
              !searchAsYouType &&
              !pathname?.includes('/search') &&
              isMobileSearchModalOpen && (
                <ReactPortal wrapperId={'mobile-search-modal'}>
                  <div
                    className={clsx(
                      'fixed bottom-0 left-0 right-0 top-0 z-[98] h-dvh w-full overflow-y-scroll bg-light-gray-200'
                    )}
                  >
                    <MobileSearchModal
                      setOpenModal={setIsMobileSearchModalOpen}
                      placeholder={placeholder!}
                      language={language}
                      region={region}
                    />
                  </div>
                </ReactPortal>
              )}
          </form>
        </div>
      </div>
      {!searchAsYouType && (
        <div
          className={clsx([
            isSuggestionsEmpty || hideSuggestions ? 'hidden' : 'block',
            'p-4 pt-0',
          ])}
        >
          <SearchBoxWithSuggestions
            onClickSuggestion={(value: string) => {
              setHideSuggestions(true);
              setQuery(value);
              refine(value);
            }}
            onCheckIsSuggestionsEmpty={(value) => setIsSuggestionsEmpty(value)}
            inputValue={inputValue}
            language={language}
            region={region}
          />
        </div>
      )}
    </div>
  ) : (
    <form
      action=''
      onSubmit={(e) => {
        onSubmit(e);
      }}
      noValidate
      className='relative w-[90%] lg:w-full lg:px-6'
      autoComplete='off'
      role='search'
    >
      <div className='absolute top-3 flex items-center transition-all lg:top-3 ltr:left-4 ltr:lg:left-10 rtl:right-4 lg:rtl:right-10'>
        <SearchIcon
          className='has-color fill-current !text-gray lg:text-white'
          style={{ fill: openMiniSearch ? '#757575' : textColor }}
        />
      </div>
      {inputRef.current?.value && (
        <button
          type='button'
          onClick={() => {
            setInputValue('');
            refine('');
            clearRefinements();
          }}
          className='absolute end-4 top-3 flex h-6 w-6 lg:end-10'
        >
          <SearchClear />
        </button>
      )}
      <SearchBarInput
        multiplePlaceholders={multiPlaceholder}
        placeholder={placeholder}
        defaultPlaceHolder={ts('search')}
        style={{ color: textColor }}
        isMobile={isMobile}
        inputRef={inputRef}
        inputOnChange={onChange}
        openMiniSearch={openMiniSearch}
        inputValue={inputValue}
        inputOnClick={onClick}
      />
      <style jsx>{`
        .headerSearchPlaceholder::placeholder {
          color: ${openMiniSearch ? '#BDBDBD' : textColor};
        }
      `}</style>
      <MiniSearch
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClickSuggestion={async (value: string) => {
          if (!pathname?.includes('/search'))
            await asyncRouter.push(`/search?query=${value}`);
          setOpenMiniSearch(false);
          setInputValue(value);
          refineAndSetRecentSearch(value);
        }}
        openMiniSearch={openMiniSearch}
        plpContentData={plpContentData!}
        searchAsYouType={searchAsYouType}
        miniSearchRef={miniSearchRef}
        inputValue={inputValue}
        language={language}
        region={region}
      />
      {isMobile &&
        !searchAsYouType &&
        !pathname?.includes('/search') &&
        isMobileSearchModalOpen && (
          <ReactPortal wrapperId={'mobile-search-modal'}>
            <div
              className={clsx(
                'fixed bottom-0 left-0 right-0 top-0 z-[98] h-dvh w-full overflow-y-scroll bg-light-gray-200'
              )}
            >
              <MobileSearchModal
                setOpenModal={setIsMobileSearchModalOpen}
                placeholder={placeholder!}
                language={language}
                region={region}
              />
            </div>
          </ReactPortal>
        )}
    </form>
  );
}
