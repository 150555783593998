import { useEffect, useRef, useState } from 'react';

export const useBannerInjector = (dualColumn: boolean, breakPoint: any) => {
  // Inject Ingrid banner insert after the plp_interruptor_break product item
  const containerRef = useRef<HTMLDivElement>(null);
  const ingridBannerRef = useRef<HTMLDivElement>(null);
  const [hasInjected, setHasInjected] = useState(false);
  const [isDualColumn, setIsDualColumn] = useState(false);

  useEffect(() => {
    const items = containerRef.current?.children;

    if (items && items.length >= breakPoint && !hasInjected) {
      if (ingridBannerRef.current && items && !dualColumn) {
        const point = isDualColumn ? breakPoint : breakPoint - 1;
        items[point]?.insertAdjacentElement(
          'afterend',
          ingridBannerRef.current
        );
        return;
      }

      if (ingridBannerRef.current && items && dualColumn) {
        setIsDualColumn(true);
        items[breakPoint]?.insertAdjacentElement(
          'afterend',
          ingridBannerRef.current
        );
        return;
      }

      setHasInjected(true);
    }
  }, [hasInjected, isDualColumn, breakPoint, dualColumn]);

  return { containerRef, ingridBannerRef };
};
