'use client';

import { useMemo } from 'react';
import {
  useSearchBox,
  useRefinementList,
  useSortBy,
  useInfiniteHits,
} from 'react-instantsearch';

import type { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import type { AlgoliaConfig, AlgoliaSortableAttribute } from 'types/algolia';

function VirtualSearchBar({ ...props }) {
  useSearchBox(props);
  return null;
}

function VirtualRefinementList({ attribute, ...props }: { attribute: string }) {
  useRefinementList({ attribute, ...props });
  return null;
}

function VirtualSortBy({
  algoliaIndexName,
  sortableAttributes,
}: {
  algoliaIndexName: string;
  sortableAttributes: AlgoliaSortableAttribute[];
}) {
  const sortItems: SortByItem[] = useMemo(() => {
    const result = sortableAttributes.map((item) => {
      const value = item.name;

      return {
        label: item.label,
        value,
      };
    });

    result.unshift({
      label: 'Relevance',
      value: algoliaIndexName,
    });

    return result;
  }, [algoliaIndexName, sortableAttributes]);

  useSortBy({
    items: sortItems,
  });
  return null;
}

function VirtualInfiniteHits({ ...props }) {
  useInfiniteHits(props);
  return null;
}

export default function VirtualWidgets({
  algoliaConfig,
  algoliaIndexName,
}: {
  algoliaConfig: AlgoliaConfig;
  algoliaIndexName: string;
}) {
  const virtualFacets = ['global_filter', 'item_has_offer'];
  const virtualFacetsFiltered = virtualFacets.filter(
    (facet) => !algoliaConfig.facets.find((f) => f.attribute === facet)
  );

  return (
    <>
      <VirtualSearchBar />
      <VirtualSortBy
        algoliaIndexName={algoliaIndexName}
        sortableAttributes={algoliaConfig.sortableAttributes}
      />
      {algoliaConfig.facets.map((facet, i) => (
        <VirtualRefinementList
          key={facet.attribute + i}
          attribute={facet.attribute}
        />
      ))}
      {virtualFacetsFiltered.map((facet, i) => (
        <VirtualRefinementList key={facet + i} attribute={facet} />
      ))}
      <VirtualInfiniteHits />
    </>
  );
}
