'use client';
import HydrationWrapper from 'components/hydration-wrapper';
import { TrendingArrow } from 'components/icon';
import { TrendingSearchesType } from 'types/cms/plp-content';
export default function TrendingKeywords({
  tredingkeywordsData,
  onClickSuggestion,
}: {
  tredingkeywordsData: TrendingSearchesType;
  onClickSuggestion: (value: string) => void;
}) {
  return (
    <div>
      <HydrationWrapper>
        <h3 className='text-custom-lg text-black'>
          {tredingkeywordsData?.header}
        </h3>
      </HydrationWrapper>
      <div className='mt-4 flex flex-wrap gap-3'>
        {tredingkeywordsData?.items?.map((item, i) => (
          <div
            key={`trending-keywords-${i}-${item.label}`}
            onClick={() => {
              onClickSuggestion(item.link);
            }}
            className='flex h-fit w-fit cursor-pointer items-center gap-2 rounded-md bg-white p-3 shadow-standart'
          >
            <TrendingArrow />
            <span className='text-custom-sm font-semibold leading-[normal] text-gray-dark'>
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
