import HeroBannerLayout from 'components/cms/banners/hero-banner';
import { HomeHeroBannerType } from 'types/cms/hero-banner';
import HeroBannerXSmall from './x-small';
import { IVideoSettings } from 'types/cms/gss';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function CmsHeroBanner({
  data,
  disableFadeEffect,
  contentPadding,
  videoSettings,
  language,
  region,
}: {
  data: HomeHeroBannerType;
  disableFadeEffect?: boolean;
  contentPadding?: string;
  videoSettings?: IVideoSettings;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <div
      className='mb-5 sm:mb-10 lg:mb-0'
      style={{
        background: data?.color_theme?.background_color,
      }}
    >
      {data.image_size_definition === 'XSMALL' ||
      data.image_size_definition_padding === 'XSMALL' ||
      data.image_size_definition_padding_horizontal === 'XSMALL' ? (
        <HeroBannerXSmall data={data} bannerType='home-hero' />
      ) : (
        <HeroBannerLayout
          data={data}
          bannerType='home-hero'
          disableFadeEffect={disableFadeEffect}
          contentPadding={contentPadding}
          videoSettings={videoSettings}
          language={language}
          region={region}
        />
      )}
    </div>
  );
}
