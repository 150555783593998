export default function SearchClear() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='12' fill='#E8E8E8' />
      <g clip-path='url(#clip0_2806_28643)'>
        <path
          d='M7.66708 16.7998C7.57436 16.7998 7.48371 16.7723 7.40661 16.7208C7.32951 16.6693 7.26941 16.5961 7.23393 16.5104C7.19844 16.4247 7.18916 16.3305 7.20725 16.2395C7.22535 16.1486 7.27001 16.0651 7.33558 15.9995L15.9981 7.33702C16.086 7.2491 16.2052 7.19971 16.3296 7.19971C16.4539 7.19971 16.5732 7.2491 16.6611 7.33702C16.749 7.42494 16.7984 7.54418 16.7984 7.66852C16.7984 7.79286 16.749 7.9121 16.6611 8.00002L7.99858 16.6625C7.95509 16.7061 7.90341 16.7407 7.84652 16.7642C7.78963 16.7878 7.72865 16.7999 7.66708 16.7998Z'
          fill='#424242'
        />
        <path
          d='M16.3296 16.7998C16.268 16.7999 16.207 16.7878 16.1501 16.7642C16.0932 16.7407 16.0415 16.7061 15.9981 16.6625L7.33555 8.00002C7.24763 7.9121 7.19824 7.79286 7.19824 7.66852C7.19824 7.54418 7.24763 7.42494 7.33555 7.33702C7.42347 7.2491 7.54272 7.19971 7.66705 7.19971C7.79139 7.19971 7.91063 7.2491 7.99855 7.33702L16.6611 15.9995C16.7266 16.0651 16.7713 16.1486 16.7894 16.2395C16.8075 16.3305 16.7982 16.4247 16.7627 16.5104C16.7272 16.5961 16.6671 16.6693 16.59 16.7208C16.5129 16.7723 16.4223 16.7998 16.3296 16.7998Z'
          fill='#424242'
        />
      </g>
      <defs>
        <clipPath id='clip0_2806_28643'>
          <rect
            width='9.6'
            height='9.6'
            fill='white'
            transform='translate(7.19824 7.19995)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
