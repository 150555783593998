'use client';

import { useMemo, useState } from 'react';

import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { useMenu, useSearchBox } from 'react-instantsearch';
import { useEffect, useRef } from 'react';
import { autocomplete } from '@algolia/autocomplete-js';

import type {
  AutocompleteApi,
  AutocompleteState,
} from '@algolia/autocomplete-js';

import '@algolia/autocomplete-theme-classic';
import { searchClient } from 'app/[lng]/instant-search-provider';
import ArrowRight from 'components/icons/arrow-right';
import { SearchIcon } from 'components/icon';
import { useTranslation } from 'app/i18n/client';
import useRouterAsync from 'hooks/use-router-async';
import { usePathname } from 'next/navigation';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

function Autocomplete(props: any) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(props.language, props.region),
    'search'
  );
  const searchInstance = useRef<AutocompleteApi<any>>();
  const [autocompleteState, setAutocompleteState] =
    useState<AutocompleteState<any>>();
  const asyncRouter = useRouterAsync();
  const pathname = usePathname();
  const { refine } = useSearchBox();
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    searchInstance.current = autocomplete({
      ...props,
      container: containerRef.current,
      onStateChange({ state }) {
        setAutocompleteState(state);
      },
    });
    return () => searchInstance.current?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchInstance?.current?.setQuery(props.inputValue);
    searchInstance?.current?.refresh();
  }, [props.inputValue]);

  const checkIsSuggestionsEmpty = () => {
    let itemsCount = 0;
    autocompleteState?.collections?.map((collection) => {
      const { items } = collection;
      itemsCount += items.length;
    });
    return itemsCount > 0 ? false : true;
  };

  useEffect(() => {
    props.onCheckIsSuggestionsEmpty(checkIsSuggestionsEmpty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteState]);

  const handleShowAll = async () => {
    if (props.onClickSuggestion) props.onClickSuggestion(props.inputValue);
    if (!pathname?.includes('search')) {
      await asyncRouter.push(`/search?query=${props.inputValue}`);
    }
    refine(props.inputValue);
  };

  return (
    <div>
      <div ref={containerRef} className='hidden' />
      <div className='mb-4 mt-6 flex items-center justify-between lg:mt-0'>
        <h3 className='text-custom-lg text-black'>{ts('results')}</h3>
        {!checkIsSuggestionsEmpty() && (
          <button
            className='text-custom-sm font-semibold text-blue underline underline-offset-4'
            onClick={() => {
              handleShowAll();
            }}
          >
            {ts('show_all')}
          </button>
        )}
      </div>
      <div className='rounded-md bg-white'>
        {!checkIsSuggestionsEmpty() &&
          autocompleteState!.collections.map((collection, index) => {
            const { items } = collection;
            return (
              <div key={`source-${index}`} className='aa-Source'>
                {items.length > 0 && (
                  <ul className='aa-List'>
                    {items.map((item, index) => (
                      <li
                        className={`flex h-[42px] cursor-pointer items-center p-3 lg:p-0 ${
                          index !== items.length - 1 &&
                          'border-1 border-b border-light-gray-100 lg:border-none'
                        }`}
                        key={index}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={async () => {
                          if (props.onClickSuggestion)
                            props.onClickSuggestion(item.query);
                          if (!pathname?.includes('search')) {
                            await asyncRouter.push(
                              `/search?query=${item.query}`
                            );
                          }
                          refine(item.query);
                        }}
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div className='flex gap-2'>
                            <SearchIcon className='has-color fill-current text-gray-600' />
                            <h3 className='text-custom-sm text-gray-dark'>
                              <span className='font-semibold'>
                                {props.inputValue}
                              </span>
                              {item.query.slice(props.inputValue.length)}
                            </h3>
                          </div>
                          <div className='block lg:hidden'>
                            <ArrowRight />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
export function SearchBoxWithSuggestions({
  inputValue,
  onCheckIsSuggestionsEmpty,
  onClickSuggestion,
  language,
  region,
}: {
  inputValue: string;
  // eslint-disable-next-line no-unused-vars
  onCheckIsSuggestionsEmpty?: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onClickSuggestion?: (value: string) => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { query } = useSearchBox();
  useMenu({ attribute: 'categories' });

  const plugins = useMemo(() => {
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      indexName: 'magento2_staging_ksa_en_products_query_suggestions',
      searchClient,
      categoryAttribute: [
        'instant_search',
        'facets',
        'exact_matches',
        'categories',
      ],
    });
    return [querySuggestionsPlugin];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = useMemo(() => ({ query }), [query]);

  return (
    <Autocomplete
      placeholder='Search for products...'
      plugins={plugins}
      initialState={initialState}
      insights={true}
      inputValue={inputValue}
      onCheckIsSuggestionsEmpty={(value: boolean) => {
        if (onCheckIsSuggestionsEmpty) onCheckIsSuggestionsEmpty(value);
      }}
      onClickSuggestion={(value: string) => {
        if (onClickSuggestion) onClickSuggestion(value);
      }}
      language={language}
      region={region}
    />
  );
}
