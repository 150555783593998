import React from 'react';
import { useHits } from 'react-instantsearch';
import Hitscontainer from './hits-container';

export default function WebHit(props: any) {
  const { hits, sendEvent } = useHits(props);

  return (
    <Hitscontainer
      dualColumn={props.dualColumn}
      isBundleLayout={props.isBundleLayout}
      products={props.products}
      inGridBannerComponentData={props.inGridBannerComponentData}
      getProducts={props.getProducts}
      setProducts={props.setProducts}
      hits={hits}
      currentPageHits={hits}
      isFirstPage={false}
      productItemsSettingsData={props.productItemsSettingsData}
      isMobileSearchModal={props.isMobileSearchModal}
      sendEvent={sendEvent}
      language={props.language}
      region={props.region}
    />
  );
}
