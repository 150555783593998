'use client';

import { useEffect, useRef, useState } from 'react';
import { GoBackIcon, SearchIcon } from 'components/icon';
import {
  useSearchBox,
  useInstantSearch,
  useHits,
  useClearRefinements,
} from 'react-instantsearch';
import { useTranslation } from 'app/i18n/client';
import NoSearchResult from '../no-search-results';
import TrendingKeywords from '../trending-keywords';
import RecentSearches from '../recent-searches';
import CustomHit from '../custom-hit';
import clsx from 'clsx';
import { setRecentSearchesLocalStorage } from 'utils';
import { Button } from 'components/buttons/button';
import useRouterAsync from 'hooks/use-router-async';
import { SearchBoxWithSuggestions } from '../suggestion-box';
import CategoriesCarousel from 'components/cms/categories/categories-carousel';
import { useSearchParams } from 'next/navigation';
import SearchClear from 'components/icons/search-clear';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppSelector } from 'redux/hooks';

export default function MobileSearchModal({
  placeholder,
  setOpenModal,
  hideSuggestionsOnSearchPage, // eslint-disable-line @typescript-eslint/no-unused-vars
  language,
  region,
}: {
  placeholder: string;
  setOpenModal: (isOpen: boolean) => void;
  hideSuggestionsOnSearchPage?: () => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { refine } = useSearchBox();
  const { refine: clearRefinements } = useClearRefinements();
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'search'
  );
  const searchParams = useSearchParams();

  const { hits } = useHits();
  const [isSuggestionsEmpty, setIsSuggestionsEmpty] = useState(true);
  const [hideSuggestions, setHideSuggestions] = useState(true);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const plpContentData = useAppSelector(
    (state) => state.plpContentReducer.plpContentData
  );
  const asyncRouter = useRouterAsync();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { status } = useInstantSearch();

  function refineAndSetRecentSearch(newQuery: string) {
    refine(newQuery);
    setRecentSearchesLocalStorage(newQuery);
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    setHideSuggestions(true);
    refineAndSetRecentSearch(inputValue);

    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setInputValue(e.currentTarget.value);
    if (hideSuggestions) {
      setHideSuggestions(false);
    }
  }
  useEffect(() => {
    if (searchParams.get('query') && searchParams.get('query') !== inputValue) {
      setInputValue(searchParams.get('query')!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPageScrolled(window.scrollY > 0);
  }, []);

  return (
    <>
      <div className={'h-16 bg-white p-4 px-4 shadow-standart lg:hidden'}>
        <div className='flex h-full items-center gap-3 ltr:flex-row rtl:flex-row-reverse'>
          <GoBackIcon
            onClick={() => {
              refine('');
              setOpenModal(false);
            }}
            className='has-color fill-current text-blue'
          />
          {/* 20px icon width and 12px padding */}
          <form
            action=''
            onSubmit={onSubmit}
            noValidate
            className='relative lg:w-full lg:px-6'
            style={{ width: 'calc(100% - 32px)' }}
            autoComplete='off'
            role='search'
          >
            <div className='absolute top-3 flex items-center lg:top-3 ltr:left-3 ltr:lg:left-10 rtl:right-3 lg:rtl:right-10'>
              <SearchIcon className='has-color fill-current text-gray-600 lg:text-white' />
            </div>
            {inputRef.current?.value && (
              <button
                type='button'
                onClick={() => {
                  setInputValue('');
                  refine('');
                  clearRefinements();
                }}
                className='absolute end-4 top-2.5 flex h-6 w-6 lg:end-10'
              >
                <SearchClear />
              </button>
            )}
            <input
              ref={inputRef}
              type='search'
              name='search'
              autoFocus
              placeholder={placeholder || ts('search')}
              className={clsx(
                'headerSearchPlaceholder | h-11 w-full rounded-lg py-3 text-[16px] text-gray-600 lg:h-12 lg:px-12 lg:text-white ltr:pl-10 rtl:pr-10',
                'border bg-white focus:!outline-transparent focus:!ring-transparent focus:!ring-offset-0 lg:border-transparent lg:bg-blue-light lg:focus:bg-blue-light',
                'placeholder:text-gray lg:placeholder:text-gray-200',
                inputValue.length > 0 ? 'border-blue' : 'border-light-gray'
              )}
              aria-label='Search'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              value={inputValue}
              onChange={onChange}
            />
          </form>
        </div>
      </div>
      <div
        className={clsx([
          isSuggestionsEmpty ||
          hideSuggestions ||
          (hits.length === 0 && status === 'idle')
            ? 'hidden'
            : 'block px-4',
        ])}
      >
        <SearchBoxWithSuggestions
          onClickSuggestion={() => async (value: string) => {
            setHideSuggestions(true);
            await asyncRouter.push(`/search?query=${value}`);
            refineAndSetRecentSearch(value);
          }}
          onCheckIsSuggestionsEmpty={(value) => setIsSuggestionsEmpty(value)}
          inputValue={inputValue}
          language={language}
          region={region}
        />
      </div>

      <div
        className={clsx([
          hits.length === 0 && status === 'idle' ? 'block' : 'hidden',
        ])}
      >
        <NoSearchResult language={language} region={region} />
      </div>
      <div
        className={clsx([
          hits.length === 0 && status === 'idle' ? 'hidden' : 'block px-4',
        ])}
      >
        <div className='flex flex-col-reverse font-montserrat tracking-[-0.3px] text-primary md:flex-col'>
          {!inputValue && (
            <div className='mt-6 lg:hidden'>
              <TrendingKeywords
                tredingkeywordsData={plpContentData?.trendingSearches!}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClickSuggestion={async (value: string) => {
                  await asyncRouter.push(`/search?query=${value}`);
                  refineAndSetRecentSearch(value);
                }}
              />
              <div className='mt-8'>
                <RecentSearches
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClickSuggestion={async (value: string) => {
                    await asyncRouter.push(`/search?query=${value}`);
                    refineAndSetRecentSearch(value);
                  }}
                  language={language}
                  region={region}
                />
              </div>
              {plpContentData?.trendingCategories && (
                <div className='-mx-4 mt-8'>
                  <CategoriesCarousel
                    componentIndex={102}
                    data={plpContentData?.trendingCategories}
                    language={language}
                    region={region}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={clsx([
            inputValue ? 'flex' : 'hidden',
            'mt-4 w-full flex-col sm:space-y-5 lg:flex-row lg:space-x-[19px] lg:space-y-0 rtl:space-x-reverse',
          ])}
        >
          <CustomHit
            dualColumn={false}
            isMobileSearchModal={true}
            language={language}
            region={region}
          />
        </div>
        <Button
          className={clsx([
            inputValue && hits.length > 0 ? 'block' : 'hidden',
            'my-6',
            isPageScrolled ? '' : 'mb-24',
          ])}
          onClick={() => {
            (async () => {
              await asyncRouter.push(`/search?query=${inputValue}`);
              refineAndSetRecentSearch(inputValue);
            })();
          }}
        >
          {ts('show_all_results')}
        </Button>
      </div>
    </>
  );
}
