'use client';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import { Underline } from 'components/buttons/underline';
import { RecentTimeIcon } from 'components/icon';
import { useEffect, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import {
  LanguageCodesType,
  RegionCodesType,
  getRecentSearchesLocalStorage,
} from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function RecentSearches({
  onClickSuggestion,
  language,
  region,
}: {
  // eslint-disable-next-line no-unused-vars
  onClickSuggestion: (value: string) => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'search'
  );
  const { query } = useSearchBox();

  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    setRecentSearches(getRecentSearchesLocalStorage()?.reverse() ?? []);
  }, [query]);

  return (
    <div className={clsx([!recentSearches?.length && 'hidden'])}>
      <div className='flex justify-between'>
        <h3 className='text-custom-lg text-black'>
          {t('recent_searches.recent_searches')}
        </h3>
        <Underline
          onClick={() => {
            localStorage.removeItem('recent_searches');
            setRecentSearches([]);
          }}
          className='!text-custom-sm text-blue'
        >
          {t('recent_searches.clear')}
        </Underline>
      </div>

      <div className='mt-4 flex flex-wrap gap-3'>
        {recentSearches.map((recentSearchItem: string, index: number) => (
          <div
            key={`recent-search-item-${recentSearchItem}-${index}`}
            className='flex h-fit w-fit cursor-pointer items-center gap-2 rounded-md bg-white p-3 shadow-standart'
            onClick={() => {
              onClickSuggestion(recentSearchItem);
            }}
          >
            <RecentTimeIcon />
            <span className='text-custom-sm font-semibold leading-[normal] text-gray-dark'>
              {recentSearchItem}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
