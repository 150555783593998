import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { HearthIcon } from 'components/icon';

const Loading = () => {
  const items = Array.from({ length: 4 }, (_, k) => k);
  const { status } = useInstantSearch();

  return (
    (status === 'loading' || status === 'stalled') && (
      <div className='mt-4 grid grid-cols-2 gap-x-2 gap-y-4'>
        {items.map((v) => (
          <div
            key={v}
            style={{
              background:
                'linear-gradient(163.87deg, #C8C8C8 0.32%, #FFFFFF 93.41%)',
            }}
            className={
              'relative h-[275px] animate-pulse rounded-lg p-2 shadow-sm'
            }
          >
            <div className='relative mx-auto h-[130px] w-11/12'>
              <button className='absolute end-0 top-0 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-sm'>
                <HearthIcon />
              </button>

              <div
                className='absolute bottom-0 end-0 z-10 flex h-8 w-8 animate-pulse items-center justify-center rounded-full shadow-sm'
                style={{
                  background:
                    'linear-gradient(140.72deg, #CDCDCD 1.82%, rgba(205, 205, 205, 0) 101.24%)',
                }}
              ></div>
            </div>

            <div className='mt-4 h-[21px] w-9/12 animate-pulse rounded-xl bg-[#CDCDCD]'></div>
            <div className='mt-2 h-[14px] w-11/12 animate-pulse rounded-xl bg-[#CDCDCD]'></div>
            <div className='mt-2 h-[14px] w-7/12 animate-pulse rounded-xl bg-[#CDCDCD]'></div>
            <div className='mt-2 h-[14px] w-5/12 animate-pulse rounded-xl bg-[#CDCDCD]'></div>
          </div>
        ))}
      </div>
    )
  );
};

export default Loading;
