import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef } from 'react';

export default function useRouterAsync() {
  const path = usePathname();
  const router = useRouter();
  const resolvePath = useRef<Function | null>(null);

  const pushCallback = (href: string, callback?: Function) => {
    router.push(href);
    if (callback) {
      resolvePath.current = callback;
    }
  };

  const push = (href: string) => {
    return new Promise((resolve) => pushCallback(href, resolve));
  };

  useEffect(() => {
    resolvePath.current?.();
  }, [path]);

  return {
    ...router,
    push,
  };
}
