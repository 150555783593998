import { AlgoliaHit, BaseHit } from 'instantsearch.js';
import { getBundleProduct } from 'lib/nahdi-middleware/product';
import { useEffect } from 'react';
import { BundleProductInfoType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const useProductUpdater = (
  currentPageHits: AlgoliaHit<BaseHit>[],
  products: any,
  setProducts: (products: BundleProductInfoType[]) => void,
  hits: AlgoliaHit<BaseHit>[],
  isBundleLayout: boolean,
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  useEffect(() => {
    const updateProducts = async () => {
      const newProductIDs = currentPageHits?.map((hit: AlgoliaHit) => {
        return hit.sku;
      });
      const newProducts = [];
      for (const productID of newProductIDs) {
        const productData = await getBundleProduct(productID, language, region);
        newProducts.push(productData);
      }
      const updatedProducts = [...products, ...newProducts];
      setProducts(updatedProducts);
    };
    if (isBundleLayout && hits?.length > (products?.length ?? 0)) {
      updateProducts();
    }
  }, [
    currentPageHits,
    hits,
    isBundleLayout,
    language,
    products,
    products?.length,
    region,
    setProducts,
  ]);
};
