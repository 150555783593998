'use client';

import { forwardRef, useRef } from 'react';
import { Field } from 'formik';
import { CheckIcon } from 'components/icons';
import { CheckboxType } from 'types/checkbox';
import Image from 'next/image';
const Checkbox = forwardRef<HTMLInputElement, CheckboxType>(
  (props, refInput) => {
    const {
      name,
      checked,
      isFormik,
      label,
      variant,
      errorMessages,
      custom_checkbox_icon_src,
    } = props;
    refInput = useRef(null);
    const isChecked = refInput?.current?.checked;
    const IconToRender = custom_checkbox_icon_src ? (
      <Image
        src={custom_checkbox_icon_src}
        alt='icon'
        width={20}
        height={20}
        className='pointer-events-none absolute inset-0'
      />
    ) : (
      <CheckIcon className='pointer-events-none absolute left-1 top-[6px]' />
    );
    return (
      <div className='relative h-5'>
        {isFormik ? (
          <label className='flex items-center space-x-1 rtl:space-x-reverse'>
            <Field
              type='checkbox'
              name={name}
              className='custom-checkbox h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50'
            />
            <span className='text-custom-sm'> {label && label}</span>
          </label>
        ) : (
          <input
            ref={refInput}
            name={name}
            type='checkbox'
            className='custom-checkbox h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50'
            {...props}
          />
        )}
        {(isChecked || checked) && IconToRender}
        {variant && (
          <span className='text-custom-xs text-error'>{errorMessages}</span>
        )}
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
