'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'app/i18n/client';

import { BundleProductItemType } from 'types/product';
import { showToast } from 'lib/toast';
import { addToBasketThunk } from 'redux/features/basket.reducer';
import clsx from 'clsx';
import { PlusIcon } from 'components/icon';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectShippingMethodModalWithPermission } from 'redux/features/address.reducer';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

export function BundleAddCart({
  products,
  buttonTypePlus,
  language,
  region,
}: {
  products: BundleProductItemType[];
  buttonTypePlus?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-detail-page'
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { guest, addressList, deliveryMode } = useAppSelector(
    (state) => state.customerReducer
  );
  const isStorePickup = deliveryMode?.mode === 'pickup';

  function handleShippingAddressModal() {
    if (
      isStorePickup ||
      addressList.length > 0 ||
      (guest?.addressList?.length ?? 0)
    ) {
      handleAddAllToCart();
    } else {
      dispatch(
        selectShippingMethodModalWithPermission({
          value: true,
          language,
          region,
        })
      );
    }
  }

  const handleAddAllToCart = async () => {
    setButtonLoading(true);
    try {
      await Promise.all(
        products.map(async (product) => {
          await dispatch(
            addToBasketThunk({
              product: {
                sku: product.sku_id,
                quantity: 1,
                isGlobalProduct: product?.shipment_type === 'global' ?? false,
              },
              language,
              region,
            })
          );
        })
      )
        .then(() => {
          showToast(ts('your_items_added'), 'success');
          setButtonLoading(false);
        })
        .catch(() => {
          showToast(ts('operation_error'), 'error');
          setButtonLoading(false);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  if (buttonTypePlus) {
    return (
      <button
        onClick={() => {
          handleShippingAddressModal();
        }}
        disabled={buttonLoading}
        className={clsx(
          'relative h-8 w-8 rounded-full bg-blue text-white',
          'flex items-center justify-center shadow-2xl transition-width duration-200',
          'group overflow-hidden',
          'disabled:bg-blue-hover'
        )}
      >
        {buttonLoading && (
          <div className='absolute bottom-0 z-[10] flex h-full w-full items-center justify-center rounded-lg bg-primary-blue'>
            <Image
              src='/assets/gifs/button-loading.gif'
              alt='Loading'
              width={0}
              height={0}
              className='mx-auto h-4 w-4'
            />
          </div>
        )}
        <PlusIcon
          className={
            'has-color absolute h-4 w-4 fill-white ltr:right-2 rtl:left-2'
          }
        />
      </button>
    );
  }

  return (
    <button
      onClick={() => {
        handleAddAllToCart();
      }}
      disabled={buttonLoading}
      className='relative h-[50px] w-full rounded-lg bg-red text-custom-base font-semibold text-white hover:bg-red/90'
    >
      {buttonLoading && (
        <div className='absolute bottom-0 z-[10] flex h-full w-full items-center justify-center rounded-lg bg-red'>
          <Image
            src='/assets/gifs/button-loading.gif'
            alt='Loading'
            width={0}
            height={0}
            className='mx-auto h-8 w-8'
          />
        </div>
      )}
      {t('get_offer_now')}
    </button>
  );
}
