import clsx from 'clsx';

export default function ArrowRight(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={clsx(props.className)}
      width='8'
      height='15'
      viewBox='0 0 8 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 7.50004C8 7.65994 7.93974 7.82 7.81969 7.94178L1.05064 14.8167C0.81007 15.0611 0.421056 15.0611 0.180453 14.8167C-0.0601155 14.5724 -0.0601155 14.1773 0.180453 13.9329L6.51455 7.50007L0.180452 1.06698C-0.0601515 0.822653 -0.0601515 0.427707 0.180452 0.18338C0.42102 -0.0611267 0.810034 -0.0611267 1.05064 0.18338L7.81969 7.05815C7.93974 7.17997 8 7.34002 8 7.50004Z'
        fill='#757575'
      />
    </svg>
  );
}
